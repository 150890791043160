import { useNavigate, useParams } from 'react-router-dom';
import './reku.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CoinList } from './component/coin-list';
import { TradeHeader } from './component/trade-header';
import { MarketTransaction } from './component/market-transaction';
import { TopInfo } from './component/top-info';
import { OrderBox } from './component/order-box';
import '../orderpopup/index.css';
import { match } from 'ts-pattern';
import { isMobile } from 'react-device-detect';
import { getText } from '@utils/util';

export default function TradeBox({
  coinname,
  coinListData,
  index,
  nowTab,
  KChartBox,
  orderBoxProps,
  orderListBox,
}: {
  coinname: string;
  coinListData: any;
  index: number;
  nowTab: string;
  KChartBox: React.ReactNode;
  orderBoxProps: any;
  orderListBox: React.ReactNode;
}) {
  console.log('faith=============orderBoxProps', orderBoxProps);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [activeOrderType, setActiveOrderType] = useState(1);
  const [isFollowMode, setIsFollowMode] = useState(false);
  const { t: translate } = useTranslation();

  return (
    <div className="container mx-auto mt-6">
      {match(isMobile)
        .with(false, () => (
          <div className="css-kfdzx3 e15mt0ai3">
            <div className="fresnel-container fresnel-greaterThanOrEqual-xl fresnel-:r1j:">
              <div className="css-17kca7y e15mt0ai2">
                <TradeHeader coinname={coinname} coinListData={coinListData} />

                <CoinList coinListData={coinListData} nowTab={nowTab} />

                {/* <MarketTransaction /> */}
              </div>
            </div>
            <div className="css-zb6k0m e15mt0ai1 !grow">
              <TopInfo
                coinname={coinname}
                coinListData={coinListData}
                isFollowMode={isFollowMode}
                setIsFollowMode={setIsFollowMode}
              />

              <div className="css-4tbftr esf1z7914 rounded-sm border border-[rgb(var(--borderColor-main))]">
                {KChartBox}
              </div>
              <div className="css-922caj egubt2n2 !min-h-auto">
                <div className="mt-4 mx-4 css-znojfv e2y03nk4">
                  <div className="css-12ti8dz css-1r3mth e2y03nk3">
                    <div
                      className="css-g8f50d e2y03nk0"
                      style={{
                        transform: 'none',
                        transformOrigin: '50% 50% 0px',
                        opacity: 1,
                      }}
                    />
                    <span className="css-l5snd e2y03nk1">Order</span>
                  </div>
                  {/* <div className=" css-1r3mth e2y03nk3">
              <span className="css-1tf6jmc e2y03nk2">Market</span>
            </div> */}
                </div>
                <div className="css-1og4gyw egubt2n0">
                  <div className="w-full grid grid-cols-2 gap-4">
                    <OrderBox
                      key={`orderBox-${1}`}
                      {...orderBoxProps}
                      type={1}
                    />
                    <OrderBox
                      key={`orderBoxOut-${2}`}
                      {...orderBoxProps}
                      type={2}
                    />
                  </div>
                </div>
                <div>{orderListBox}</div>
              </div>
            </div>
          </div>
        ))
        .otherwise(() => (
          <div className="flex-col w-full pb-20">
            <TopInfo
              coinname={coinname}
              coinListData={coinListData}
              isFollowMode={isFollowMode}
              setIsFollowMode={setIsFollowMode}
            />

            <div className="w-full border border-[rgb(var(--borderColor-main))] rounded-sm">
              {KChartBox}
            </div>

            <div className="mt-4 px-4">
              <div className="mt-4">{orderListBox}</div>
            </div>

            <div className="fixed bottom-16 left-0 right-0 flex w-full bg-white border-t border-gray-200 pb-safe space-x-4 p-4 z-50">
              <button
                className="flex-1 h-11 bg-[#19a8ac] text-white font-medium active:opacity-90 transition-opacity duration-150 rounded-lg"
                onClick={() => {
                  setActiveOrderType(1);
                  setShowOrderModal(true);
                }}
              >
                {translate(getText('看涨'))}{' '}
                <span className="text-[10px]">
                  {isFollowMode ? '(Follow)' : '(Contract)'}
                </span>
              </button>
              <button
                className="flex-1 h-11 bg-[linear-gradient(90deg,#ef4444_0%,rgba(239,68,68,0.9)_100%)] text-white font-medium active:opacity-90 transition-opacity duration-150 rounded-lg"
                onClick={() => {
                  setActiveOrderType(2);
                  setShowOrderModal(true);
                }}
              >
                {translate(getText('看跌'))}
                <span className="text-[10px]">
                  {isFollowMode ? '(Follow)' : '(Contract)'}
                </span>
              </button>
            </div>

            <div
              className={`fixed inset-0 z-50 bg-black/50 transition-all duration-300 pointer-events-none ${
                showOrderModal ? 'opacity-100 pointer-events-auto' : 'opacity-0'
              }`}
              onClick={() => setShowOrderModal(false)}
            >
              <div
                className={`fixed bottom-0 left-0 right-0 w-full bg-white rounded-t-2xl p-4 transform transition-all duration-500 ease-out ${
                  showOrderModal ? 'translate-y-0' : 'translate-y-full'
                }`}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-lg font-medium">
                    {translate(
                      getText(activeOrderType === 1 ? '看涨' : '看跌')
                    )}{' '}
                    {coinname}
                  </h3>
                  <button
                    className="p-2"
                    onClick={() => setShowOrderModal(false)}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <OrderBox
                  {...orderBoxProps}
                  type={activeOrderType}
                  isFollowMode={isFollowMode}
                />
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}
